'use client'

import { RiCloseFill, RiHome2Line } from '@remixicon/react'
import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'

import Conversations from '@/components/Admin/Conversations/Conversations'

interface ConversationsListProps {
  onClose?: () => void
}

const ConversationsList: React.FC<ConversationsListProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const { setSelectedConversation } = useDrawerState()

  return (
    <div className='size-full bg-surface p-1.5 text-on-surface dark:bg-dark-surface dark:text-dark-on-surface'>
      <div className='z-50 flex w-full items-center justify-between border-b-2 p-2'>
        <Button
          type='primary'
          className='flex items-center'
          onClick={() => {
            onClose && onClose()
            setSelectedConversation(undefined)
          }}
          icon={<RiHome2Line size={20} />}
        >
          {t('conversations-new')}
        </Button>
        {onClose && (
          <Button
            type='text'
            className='flex items-center justify-center !p-2'
            onClick={onClose}
          >
            <RiCloseFill size={20} />
          </Button>
        )}
      </div>

      <Conversations onClose={onClose} />
    </div>
  )
}

export default ConversationsList
