'use client'

import { Drawer } from 'antd'
import React, { useContext } from 'react'

import useThemeState from '@/hooks/context/useThemeState'

import { colors } from '@/branding-config'
import { DrawerContext } from '@/context/conversationDrawer/drawer.context'

import ConversationsList from './ConversationsList'

const ConversationsDrawer = () => {
  const { theme } = useThemeState()
  const { open, close } = useContext(DrawerContext)

  return (
    <Drawer
      placement='right'
      closable={false}
      onClose={close}
      open={open}
      styles={{
        body: { backgroundColor: colors[theme].surface, padding: 0 },
      }}
    >
      <ConversationsList onClose={close} />
    </Drawer>
  )
}

export default ConversationsDrawer
