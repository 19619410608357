'use client'

import { Col, Grid, Row } from 'antd'
import { useSearchParams } from 'next/navigation'
import React, { PropsWithChildren, useEffect, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useAuth from '@/hooks/useAuth'

import ConversationsDrawer from '@/components/Admin/Conversations/ConversationsDrawer'
import ConversationsList from '@/components/Admin/Conversations/ConversationsList'

import { generateUUID } from '@/utils'
import { cn } from '@/utils/clsx'

const { useBreakpoint } = Grid

interface ChatLayoutProps extends PropsWithChildren {
  className?: string
}

const ChatLayout: React.FC<ChatLayoutProps> = ({ className, children }) => {
  const screens = useBreakpoint()
  const [searchParams] = useSearchParams()
  const { user } = useAuth()
  const [firstLoad, setFirstLoad] = useState(true)
  const { setSelectedConversation, selectedConversation } = useDrawerState()

  useEffect(() => {
    if (searchParams && firstLoad) {
      setSelectedConversation(searchParams[1])
      setFirstLoad(false)
    }
  }, [searchParams])

  useEffect(() => {
    if (!selectedConversation) {
      setSelectedConversation(generateUUID())
    }
  }, [])

  return (
    <>
      <Row>
        {user?.email && (
          <Col
            span={6}
            className={cn(
              'hide-scrollbar !hidden w-full lg:!block h-screen',
              className
            )}
          >
            {/* when screen size > lg; then show block on the left side */}
            <ConversationsList />
          </Col>
        )}
        <Col span={screens.lg && user?.email ? 18 : 24} className='w-full'>
          {children}
        </Col>
      </Row>
      <ConversationsDrawer />
    </>
  )
}

export default ChatLayout
